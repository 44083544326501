import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Player from "../components/player"

const CardWrapper = styled.div`
  padding-right: 2rem;
  padding-left: 2rem;
`

const StyledCard = styled.div`
  border-radius: 2rem;
  box-shadow: 0 0.25rem 0.5rem 0.1rem #ddd;
  position: relative;
  color: #4a4a4a;
  max-width: 100%;
  background-color: white;
`

const DropdownWrapper = styled.div`
  display: block;
  text-align: center;
  padding-top: 1rem;
`

class Part3 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      words: undefined,
      rand: new Date(),
    }

    this.mixHandler = this.mixHandler.bind(this)
    this.spellHandler = this.spellHandler.bind(this)

    this.cells = []
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }

  mixHandler() {
    this.setState((prevState) => ({
      words: this.shuffle(prevState.words),
      rand: new Date(),
    }))
  }

  spellHandler = (event) => {
    const currentCell = Number(event.target.attributes.cellsref.value)
    const nextCell = currentCell + 1
    const totalCells = this.cells.length - 1 // Correct for cellsref counting from zero vs length counting from one
    if (currentCell !== totalCells) {
      if (event.target.value === event.target.name) {
        this.cells[nextCell].focus()
        setTimeout(() => this.cells[nextCell].click(), 500)
      }
    }
  }

  render() {
    return (
      <CardWrapper>
        <StyledCard>
          <div className="columns pt-3 pb-3 mb-4">
            <div className="column is-one-third">
              {this.state.words !== undefined ? (
                <br />
              ) : (
                <>
                  <b>Choose a list</b>
                  <br />
                </>
              )}
              {this.props.data.Content.map((list, key) => (
                <React.Fragment key={key}>
                  <button
                    className={`button mb-5 ${
                      this.state.words === this.props.data.Content[key] &&
                      "is-primary"
                    }`}
                    onClick={(e) => {
                      e.preventDefault()
                      this.setState({ words: this.props.data.Content[key] })
                    }}
                  >
                    {`List ${key + 1}`}
                  </button>
                  <br />
                </React.Fragment>
              ))}
              {this.state.words !== undefined ? (
                <DropdownWrapper className="dropdown is-hoverable">
                  <div className="dropdown-trigger">
                    <button
                      className="button"
                      aria-haspopup="true"
                      type="button"
                    >
                      <span>See All Words</span>
                    </button>
                  </div>
                  <div className="dropdown-menu">
                    <div className="dropdown-content">
                      {this.state.words.map((word) => (
                        <div className="dropdown-item" key={word}>
                          {word}
                        </div>
                      ))}
                    </div>
                  </div>
                </DropdownWrapper>
              ) : undefined}
            </div>
            <div className="column has-background-success mr-5">
              <b>{this.props.data.Title}</b>
              {this.state.words !== undefined ? (
                <>
                  {this.state.words.map((word, contentkey) => (
                    <div
                      key={`${this.state.rand}-${contentkey}-${word}`}
                      className="mb-4"
                    >
                      <Player source={word}>
                        {contentkey === 0 ? (
                          <span
                            cellsref={contentkey}
                            type="text"
                            className="input is-size-4"
                            ref={(ref) => {
                              this.cells.splice(contentkey, 1, ref)
                            }}
                            autoComplete="off"
                            spellCheck="false"
                          >
                            {word}
                          </span>
                        ) : (
                          <input
                            cellsref={contentkey}
                            type="text"
                            className="input is-size-4"
                            onChange={this.spellHandler}
                            name={word}
                            ref={(ref) => {
                              this.cells.splice(contentkey, 1, ref)
                            }}
                            autoComplete="off"
                            spellCheck="false"
                          />
                        )}
                      </Player>
                    </div>
                  ))}
                  <button
                    className="button is-danger"
                    onClick={this.mixHandler}
                  >
                    Mix
                  </button>
                </>
              ) : undefined}
            </div>
          </div>
        </StyledCard>
      </CardWrapper>
    )
  }
}
Part3.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Part3
