import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { FaThLarge } from "react-icons/fa"
import { Link } from "gatsby"
import Player from "./player"

const CardWrapper = styled.div`
  padding-right: 2rem;
  padding-left: 2rem;
`

const StyledCard = styled.div`
  border-radius: 2rem;
  box-shadow: 0 0.25rem 0.5rem 0.1rem #ddd;
  position: relative;
  color: #4a4a4a;
  max-width: 100%;
  background-color: white;
  padding: 2rem;
  font-size: 1.25rem;
`

const KeySyllables = (props) => (
  <CardWrapper>
    <StyledCard>
      <div className="columns">
        <div className="column has-text-centered">
          <h4 className="subtitle is-4">Key Syllables</h4>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            <u>Syllable</u>
          </p>
          {props.keysyllables.map((ks) => (
            <Player key={`key-${ks.syllable}`} source={ks.syllable} icon>
              {ks.syllable}
            </Player>
          ))}
          {props.syllablegrid ? (
            <>
              <br />
              <Link to={props.syllablegrid}>
                <FaThLarge size="2.5em" />
              </Link>
            </>
          ) : undefined}
        </div>
        <div className="column">
          <p>
            <u>Whole Word</u>
          </p>
          {props.keysyllables.map((ks) => {
            let splitword = ks.word.split(" ")
            if (splitword[0] === ks.syllable) {
              splitword[0] = `<span class="has-text-weight-bold">${splitword[0]}</span>`
            } else if (splitword[1] === ks.syllable) {
              splitword[1] = `<span class="has-text-weight-bold">${splitword[1]}</span>`
            }
            let styledword = splitword.join(" ")
            return (
              <Player
                key={`key-${ks.syllable}`}
                source={ks.word.replace(/\s/g, "")}
                icon
              >
                <span dangerouslySetInnerHTML={{ __html: styledword }}></span>
              </Player>
            )
          })}
          {props.wholewordgrid ? (
            <>
              <br />
              <Link to={props.wholewordgrid}>
                <FaThLarge size="2.5em" />
              </Link>
            </>
          ) : undefined}
        </div>
      </div>
    </StyledCard>
  </CardWrapper>
)
KeySyllables.propTypes = {
  keysyllables: PropTypes.array.isRequired,
  syllablegrid: PropTypes.string.isRequired,
  wholewordgrid: PropTypes.string.isRequired,
}

export default KeySyllables
