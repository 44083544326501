/* eslint jsx-a11y/anchor-is-valid:0 */
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { FaThLarge, FaPlayCircle, FaTh, FaBorderAll } from "react-icons/fa"
import Player from "../components/player"
import styled from "styled-components"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import dash from "../images/dash.svg"
import Part3 from "../components/part3"
import Tracker from "../components/tracker"
import KeySyllables from "../components/keysyllables"
import { WordsMusicToggle } from "../components/toggle"
import CardBack from "../images/card_back.svg"
import Demo from "../components/demo"

const HeaderWrapper = styled.div`
  padding-right: 6rem;
  padding-left: 6rem;
  padding-top: 1rem;
  @media (max-width: 769px) {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
  }
`

const StyledHeader = styled.b`
  font-size: 2rem;
  margin-right: 0.75rem;
`

const Dash = styled.img`
  width: 5rem;
  display: block;
`

const StyledCard = styled.div`
  border-radius: 2rem;
  box-shadow: 0 0.25rem 0.5rem 0.1rem #ddd;
  position: relative;
  color: #4a4a4a;
  max-width: 100%;
  background-color: white;
`

const StyledCardHeader = styled.div`
  border: solid 1px none;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`

const CardImg = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
`

const StyledCardContent = styled.div`
  margin-top: 2rem;
`

const WordWrapper = styled.div`
  padding: 0.5rem;
  font-size: 1.5rem;
`

const LinkWrapper = styled.div`
  margin-top: 1rem;
  padding-bottom: 1rem;
`

const ToggleWrapper = styled.div`
  @media (max-width: 769px) {
    margin-top: 3rem;
  }
`

const ColorNames = ["primary", "info", "success"]

function WordfamilyTemplate(props) {
  const [method, setMethod] = useState(false)
  const toggle = () => setMethod(!method)
  const [music, setMusic] = useState(
    props.data.strapiWordfamilyaudio.audiolink[0].url
  )
  return (
    <Layout>
      <SEO title={props.data.strapiWordfamily.Title} />
      <HeaderWrapper
        className="columns"
        style={
          props.data.strapiWordfamily.Demo.length === 0
            ? { paddingBottom: "4rem" }
            : undefined
        }
      >
        <div className="column has-text-centered">
          <h2 className="subtitle is-4 is-pulled-left">
            <StyledHeader>Practice:</StyledHeader>
            {props.data.strapiWordfamily.Title}&nbsp;
            <Tracker id={props.data.strapiWordfamily.id} />
            <Dash src={dash} alt="some dashes" />
          </h2>
        </div>
        <ToggleWrapper className="column">
          <div className="dropdown is-hoverable is-right is-pulled-right">
            <div className="dropdown-trigger">
              <div
                role="button"
                tabIndex="0"
                onClick={toggle}
                onKeyPress={toggle}
              >
                <WordsMusicToggle toggle={method ? undefined : true} />
              </div>
            </div>

            {method ? (
              <div className="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  {props.data.strapiWordfamilyaudio.audiolink.map((audio) => (
                    <a
                      href="#"
                      key={audio.url}
                      className={
                        music === audio.url
                          ? " dropdown-item is-active"
                          : "dropdown-item"
                      }
                      role="button"
                      onClick={() => setMusic(audio.url)}
                      onKeyPress={() => setMusic(audio.url)}
                    >
                      {audio.name}
                    </a>
                  ))}
                </div>
              </div>
            ) : undefined}
          </div>
        </ToggleWrapper>
      </HeaderWrapper>
      {props.data.strapiWordfamily.Demo.length > 0 ? (
        <Demo items={props.data.strapiWordfamily.Demo} />
      ) : undefined}
      <div className="columns is-8 is-multiline is-variable">
        {props.data.strapiWordfamily.panels.map((panel, key) => {
          return (
            <React.Fragment key={`wf-column-${key}`}>
              <div className="column has-text-centered is-one-third">
                <StyledCard>
                  <StyledCardHeader
                    className={`has-background-${ColorNames[key % 3]}`}
                  >
                    <div className="columns is-flex-touch">
                      <div className="column is-family-secondary is-size-3 has-text-grey-darker">
                        <Player source={panel.example_word}>
                          {panel.example_word}
                        </Player>
                      </div>
                    </div>
                  </StyledCardHeader>
                  <CardImg src={panel.image} alt="example sentence usage" />
                  <StyledCardContent>
                    {method ? (
                      <Player key={`${method}-${music}`} source={music}>
                        <FaPlayCircle
                          className="icon is-medium"
                          color="#fc7059"
                        />
                      </Player>
                    ) : (
                      <Player
                        key={`${method}-${music}`}
                        source={panel.setaudio}
                      >
                        <FaPlayCircle
                          className="icon is-medium"
                          color="#fc7059"
                        />
                      </Player>
                    )}
                    {panel.wordset.map((word) => {
                      return (
                        <WordWrapper key={word}>
                          <Player source={word} icon>
                            {word.replace(/[_-]/g, " ")}
                          </Player>
                        </WordWrapper>
                      )
                    })}
                    <LinkWrapper>
                      <Link to={panel.speedgrid}>
                        <FaThLarge size="2.5em" />
                      </Link>
                      {panel.game ? (
                        <>
                          &emsp;
                          <Link to={panel.game}>
                            <img
                              className="icon has-text-info is-large"
                              src={CardBack}
                              alt="back of playing card"
                            />
                          </Link>
                        </>
                      ) : undefined}
                    </LinkWrapper>
                  </StyledCardContent>
                </StyledCard>
              </div>
              {props.data.strapiWordfamily.combinedgrids
                ? props.data.strapiWordfamily.combinedgrids[(key + 1) / 3 - 1]
                  ? (key + 1) % 3 === 0 && (
                      <div className="column has-text-centered is-full">
                        <Link
                          to={
                            props.data.strapiWordfamily.combinedgrids[
                              (key + 1) / 3 - 1
                            ]
                          }
                        >
                          <FaTh size="3em" />
                        </Link>
                        {props.data.strapiWordfamily.combinedgames ? (
                          <>
                            &emsp;
                            <Link
                              to={
                                props.data.strapiWordfamily.combinedgames[
                                  (key + 1) / 3 - 1
                                ]
                              }
                            >
                              <img
                                className="icon has-text-info is-large"
                                src={CardBack}
                                alt="back of playing card"
                              />
                            </Link>
                          </>
                        ) : undefined}
                        {props.data.strapiWordfamily.combinedrimes &&
                        props.data.strapiWordfamily.combinedrimes[
                          (key + 1) / 3 - 1
                        ] ? (
                          <>
                            &emsp;
                            <Link
                              to={
                                props.data.strapiWordfamily.combinedrimes[
                                  (key + 1) / 3 - 1
                                ].URL
                              }
                            >
                              <FaBorderAll size="3em" />
                            </Link>
                          </>
                        ) : undefined}
                      </div>
                    )
                  : undefined
                : undefined}
            </React.Fragment>
          )
        })}
      </div>
      {props.data.strapiWordfamily.Part3 ? (
        <div className="columns is-centered">
          <div className="column has-text-centered is-6">
            <Part3 data={props.data.strapiWordfamily.Part3} />
          </div>
          <div className="column is-6">
            <KeySyllables
              keysyllables={props.data.strapiWordfamily.keysyllables}
              syllablegrid={props.data.strapiWordfamily.syllablegrid}
              wholewordgrid={props.data.strapiWordfamily.wholewordgrid}
            />
          </div>
        </div>
      ) : undefined}
    </Layout>
  )
}
WordfamilyTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WordfamilyTemplate

export const query = graphql`
  query WordfamilyTemplate($id: String!) {
    strapiWordfamily(id: { eq: $id }) {
      Title
      id
      combinedgrids
      combinedgames
      combinedrimes {
        URL
        id
      }
      panels {
        example_word
        image
        setaudio
        speedgrid
        wordset
        game
      }
      Part3 {
        Content
        Title
      }
      keysyllables {
        syllable
        word
      }
      syllablegrid
      wholewordgrid
      Demo {
        MP3
        id
        Text
      }
    }
    strapiWordfamilyaudio {
      audiolink {
        url
        name
      }
    }
  }
`
